import React from "react"
import styled from "styled-components"
import SushiGirl from "src/assets/images/new/jak_zamawiac_sushi.jpg"
import { Box, Header, Text, Flex } from "src/styled"
import { devices } from "src/theme/device"
import { Footer } from "src/modules/Main"
import theme from "src/theme"
import { useSelector } from "react-redux"
const Container = styled(Box)`
    padding: 10px 50px 50px;
    ${devices.tablet} {
        padding: 10px;
    }
`

const Paragraph = styled(Flex)`
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    margin: auto;
    width: 50%;
    padding-right: 30px;

    ${devices.tablet} {
        padding-right: 0;
    }

    h1 {
        font-size: 38px;
        margin: 20px 0;
    }
    p {
        padding-top: 20px;
        font-size: 18px;
    }

    ${devices.tablet} {
        width: 100%;
        h1 {
            font-size: 30px;
            margin-bottom: 16px;
        }
        p {
            padding-top: 10px;
            font-size: 16px;
        }
    }
`
const ImageContainer = styled(Flex)`
    width: 50%;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        filter: ${({ shadow }) =>
            shadow === "left"
                ? "drop-shadow(-40px 40px 5px #e2e0e0)"
                : "drop-shadow(40px 40px 5px #e2e0e0)"};
    }

    ${devices.tablet} {
        margin: auto;
        margin-top: 30px;
        width: 100%;
        img {
            width: 100%;
            filter: none;
        }
    }
`
const Section = styled(Box)`
    padding: 40px 0;
    ${devices.tablet} {
        padding: 20px;
        text-align: justify;
    }
    p {
        color: ${theme.colors.secondary};
    }
`
const DescriptionContainer = styled(Flex)`
    ${devices.tablet} {
        flex-direction: column;
    }
`

const MAP_SRC =
    "https://www.google.com/maps/d/embed?mid=1nyu6lP-p9fJIxq9m7fsYZn-LOxP5uek&ehbc=2E312F"
// "https://www.google.com/maps/d/u/0/embed?mid=15Vc_H6d6NXubd6HGyVSKXBOqAqCBOFU&ehbc=2E312F"

const MapIFrame = styled.iframe`
    display: flex;
    margin: auto;
    height: 400px;
    width: 80%;
    border: 0;
    ${devices.tablet} {
        width: 95%;
    }
`
const StyledText = styled(Text)`
    font-size: 18px;
    text-align: justify;
`
const StyledHeader = styled(Header)`
    margin-botto: 20px;
`

export default function About() {
    const { ZONE_A, ZONE_B } = useSelector(state => state.prices)
    return (
        <React.Fragment>
            <Container>
                <Section>
                    <DescriptionContainer>
                        <Paragraph>
                            <StyledHeader>Jak zamówić sushi?</StyledHeader>
                            <StyledText>
                                Na naszej stronie mają Państwo możliwość
                                wykonania zamówienia, które dowieziemy pod
                                wskazany adres lub zostanie przygotowane do
                                odbioru osobistego w naszej restauracji przy
                                ulicy Kolejowej 45. Nasi kierowcy dostarczają
                                zamówione zestawy w przedziałach godzinowych,
                                gdzie pierwszy z nich to 12:00 – 13:00. W
                                związku z tym po złożeniu zamówienia zawsze
                                zadzwonimy do Was w celu potwierdzenie danego
                                przedziału godzinowego.
                            </StyledText>
                        </Paragraph>

                        <ImageContainer>
                            <img src={SushiGirl} alt="sushi" />
                        </ImageContainer>
                    </DescriptionContainer>
                </Section>

                <Section>
                    <StyledHeader mb={20}>Zamówienia online</StyledHeader>
                    <StyledText fontSize={18}>
                        Proces zamawiania naszego sushi nie różni się niczym od
                        korzystania z dobrze znanych sklepów internetowych. W
                        sekcji „Zamów online” należy wybrać interesujący produkt
                        znajdując go na liście lub wpisując nazwę w
                        wyszukiwarce. Po znalezieniu produktu należy wybrać
                        ilość zamawianych sztuk, a następnie dodać do koszyka.
                        Zawartość koszyka można w każdej chwili sprawdzić
                        klikając ikonę w prawym górnym rogu ekranu. Kiedy
                        wszystkie produkty zostaną wybrane należy przejść do
                        podsumowania zamówienia. Zostanie wtedy wyświetlona
                        pełna lista zamówionych produktów oraz szczegóły
                        dotyczące dostawy.
                    </StyledText>
                </Section>

                <Section>
                    <StyledHeader mb={20}>Sposoby płatności</StyledHeader>
                    <StyledText fontSize={18}>
                        Przyjmujemy płatności w gotówce, kartą podczas odbioru
                        oraz online (BLIK i szybkie przelewy). Współpracujemy z
                        Tpay, który pozwala nam w realizacji płatności online w
                        sposób szybki oraz bezpieczny. Opłacić zamówienie można
                        dopiero po potwierdzeniu godziny dostarczenia zamówienia
                        telefonicznie z naszym konsultantem. Po uzgodnieniu
                        szczegółów na Twój adres mailowy zostanie wysłany link,
                        który pozwoli Ci wykonać płatność.
                    </StyledText>
                </Section>

                <Section>
                    <StyledHeader mb={20}>Dostawa</StyledHeader>
                    <StyledText fontSize={18}>
                        Dostawa jest realizowana przez naszych kierowców, a jej
                        cena uzależniona jest od stefy w jakiej znajduje się
                        klient. Podzieliliśmy Warszawę na dwie strefy zamówień.
                        W strefie A koszt dostawy wynosi {ZONE_A} zł, w strefie
                        B koszt dostawy wynosi {ZONE_B} zł.
                    </StyledText>
                </Section>

                <MapIFrame title="maps" src={MAP_SRC}></MapIFrame>
            </Container>

            <Footer />
        </React.Fragment>
    )
}
